<template>
  <div>
    <div style="min-height: calc(100vh - 158px);" class="card">
      <div style="background: #DBE7F2" class="p-2">
        <TitleButton
          btnTitle="Add New"
          :showBtn="false"
          :showSettingBtn="false"
          :showAddNew="false"
          title="PO Approval Register"
        />
        <DateQuerySetter @onClickGo="getPOList">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <label for="colFormLabel" class="col-form-label">PO Number</label>
            <input class="form-control" placeholder="Input PO number" type="text" name="po_number" v-model="po_number">
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <label class="col-form-label">Supplier</label>
            <vField v-model="supplier_id" class="d-none" name="supplier_id"/>
            <v-select
              placeholder="Select Supplier"
              v-model="supplier_id"
              :options="contacts"
              label="name"
              :reduce="name => name.id"
            />
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <label for="colFormLabel" class="col-form-label">Requester</label>
            <v-select
              placeholder="Select Requester"
              v-model="POUserId"
              :options="POUsers"
              label="name"
              :reduce="name => name.id"
            />
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-3">
            <label for="colFormLabel" class="col-form-label">Status</label>
            <vField v-model="status" class="d-none" name="status"/>
            <v-select
              placeholder="Select Status"
              v-model="status"
              :options="allStatus"
              label="name"
              :reduce="name => name.id"
            />
          </div>

          <div class="col-12 col-sm-6 col-md-6 col-lg-4">
            <label for="colFormLabel" class="col-form-label">Quick Search</label>
            <input class="form-control" type="text" name="status" v-model="quick_search">
          </div>
        </DateQuerySetter>
      </div>

      <ListTable
        :approvalPageId="pageId"
        :workflowDetails="workflowDetails"
        :po-list="poList"
        @statusUpdated="getPOList()"
      />
      <div class="mb-2"></div>
      <div class="px-2 position-absolute bottom-0">
        <Pagination
          :offset="offset"
          @onPageChange="onPageChange"
          ref="pagination"
        />
      </div>
    </div>
    <GlobalLoader/>
  </div>
</template>

<script setup>
import TitleButton        from '@/components/atom/TitleButton'
import DateQuerySetter    from '@/components/atom/DateQuerySetter'
import ListTable          from '@/components/molecule/procurement/purchase-order/purchase-order-entry/approval/POListingTable.vue'
import Pagination         from '@/components/atom/Pagination'
import {
  inject,
  ref,
  onMounted,
  computed,
  watch
}                         from 'vue';
import {
  useRoute,
  useRouter
}                         from "vue-router";
import handleContact      from '@/services/modules/contact'
import handleRequisitions from "@/services/modules/procurement/requisition";
import handleInventory    from "@/services/modules/inventory";
import {useStore} from "vuex";
import handleWorkflow from "@/services/modules/approvalMatrix/workflowMatrix";

const router  = useRouter()
const route   = useRoute()
const store = useStore();
const loading = ref(false);

const showError = inject('showError');
const showSuccess = inject('showSuccess');

const {fetchContactProfiles} = handleContact()
const {fetchPOList, fetchPOUsersList} = handleRequisitions();
const {fetchHome} = handleInventory();
const {fetchWorkflowFromPageId} = handleWorkflow();

const poList = ref({});
const offset = ref(20);
const page = ref(1);
const loader = ref(false);
const contacts = ref([])
const homeContent = ref([])
const POUsers = ref([])
const POUserId = ref(null)
const supplier_id = ref(null)
const po_number = ref('')
const quick_search = ref('')
const status = ref(null)
const pageId = route.params.pageId;
const workflowDetails = ref({});


const companyId = computed(() => route.params.companyId);
const allStatus = computed(() => {
  let approvalStatus = [];
  if(homeContent.value.hasOwnProperty('approval_status')) {
    Object.keys(homeContent.value.approval_status).forEach( id => {
      approvalStatus.push({
        id: id,
        name: homeContent.value.approval_status[id],
      })
    })
  }
  return approvalStatus;
})

function getQuery() {
  page.value = route.query.page
  let query = '?company_id=' + companyId.value
  query += '&offset=' + offset.value
  query += '&start_date=' + route.query.start
  query += '&end_date=' + route.query.end
  query += '&supplier_id=' + (supplier_id.value == null ? '' : supplier_id.value)
  query += '&user_id=' + (POUserId.value == null ? '' : POUserId.value)
  query += '&po_number=' + po_number.value
  query += '&status=' + (status.value == null ? '' : status.value)
  query += '&review=1'
  query += '&without_user_check=1'
  query += '&page_id=' + pageId
  if (page.value) query += '&page=' + page.value
  return query
}

function onClickAddNewButton() {
  alert('Add New Button clicked');
}

function onClickSettingButton() {
  alert('Setting Button clicked');
}

async function getPOList() {
  loader.value = true
  poList.value = [];
  try {
    const res = await fetchPOList(getQuery());
    if (res.status) {
      poList.value = res.data;
      setPagination(res.data);
    } else {
      poList.value = {};
    }
    loader.value = false
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
    loader.value = false
  }
}

function onPageChange(page) {
  let routeQuery  = Object.assign({}, route.query)
  routeQuery.page = page
  router.push({path: route.path, query: routeQuery})
  getPOList();
}

function setPagination(data) {
  if(!data.data.length) return
  store.commit('setPaginationData', {
    records: data.total,
    perPage: data.per_page,
    currentPage: data.current_page,
  })
}

onMounted(async () => {
  loader.value       = true
  const companyQuery = `?company_id=${companyId.value}`
  let workflowQuery = companyQuery + `&approval_page_id=${route.params.pageId}`;

  Promise.all([
    getPOList(),
    fetchContactProfiles(companyQuery).then(res => {
      if (res.data) contacts.value = res.data
    }),
    fetchHome().then( res => {
      homeContent.value = res.data;
    }),
    fetchPOUsersList(companyQuery).then( res => {
      if(res.status) {
        POUsers.value = res.data;
      }
    }),
    fetchWorkflowFromPageId(workflowQuery).then(res => {
      if(res.data) workflowDetails.value = res.data
    }),
  ])
    .then(() => {
      loader.value = false;
    })
    .catch((err) => {
      loader.value = false
    })
})
</script>
